import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { extractData, formatYmal, formatProducts } from 'utils'

import {
	Carousel,
	ControlPanel,
	Footer,
	Layout,
	MainLogo,
	ProductDescription,
	Slideshow
} from 'components'
import { WebSocketsProvider } from 'context'

import * as styles from './collection.module.scss'

const ProductTemplate = ({ data, pageContext }) => {
	const ymal = formatYmal(
		extractData(data.ymalProducts),
		extractData(data.ymalProductImages)
	)

	const products = formatProducts(
		extractData(data.allProductsCsv),
		extractData(data.allImageSharp),
		ymal
	)

	const [product, setProduct] = useState(products[0]);
	const [initialIndex, setInitialIndex] = useState(null);

	const shelf = pageContext.shelf
	const carouselImages = products.map(product => ({
		...product.image,
		name: `${product.collection_name ?? ''} ${product.name}`,
		shelf: shelf.replace('shelf ', 'Shelf #')
	}))

	const updateProduct = index => {
		setProduct(products[index])
	}

	useEffect(()=>{
		const queryParameters = new URLSearchParams(window.location.search);
		const productIndex = queryParameters.get('product_index');
		if (productIndex) {
			setInitialIndex(productIndex);
			updateProduct(productIndex);
		}
	}, []);

	return (
		<WebSocketsProvider enableTimer>
			<ControlPanel />
			<Layout title={shelf} background={`prod${product.product_id}`}>
				<div className={styles.container}>
					<MainLogo />
					<Slideshow
						inline
						product={product.product_id}
						collection={product.collection_id}
					/>
					<ProductDescription
						inline
						product={product}
						ymalProducts={product.ymalProducts}
					/>
					<div className={styles.carouselContainer}>
						<Carousel
							label
							images={carouselImages}
							clickHandler={updateProduct}
							initialIndex={initialIndex}
						/>
					</div>
				</div>
				<div className={styles.footerContainer}>
					<Footer
						backButton
						product={`${product.collection_name} Collection`}
					/>
				</div>
			</Layout>
		</WebSocketsProvider>
	)
}

export const query = graphql`
	query inlineCollection($collection_id: String, $assets: String) {
		allProductsCsv(filter: { collection_id: { eq: $collection_id } }) {
			edges {
				node {
					id
					YMAL
					asset_filename
					brand_name
					collection_id
					collection_name
					description
					name
					location
					product_id
					shelf_id
				}
			}
		}
		allImageSharp(filter: { fluid: { originalName: { glob: $assets } } }) {
			edges {
				node {
					gatsbyImageData(
						placeholder: BLURRED
						layout: CONSTRAINED
						height: 320
					)
					parent {
						... on File {
							name
						}
					}
				}
			}
		}
		ymalProducts: allProductsCsv {
			edges {
				node {
					name
					product_id
					description
					asset_filename
				}
			}
		}
		ymalProductImages: allImageSharp {
			edges {
				node {
					gatsbyImageData
					parent {
						... on File {
							name
						}
					}
				}
			}
		}
	}
`

export default ProductTemplate
